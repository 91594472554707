@media (prefers-color-scheme: dark) {
  .wmde-markdown, .wmde-markdown-var {
    color-scheme: dark;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
    --color-fg-subtle: #484f58;
    --color-canvas-default: #0d1117;
    --color-canvas-subtle: #161b22;
    --color-border-default: #30363d;
    --color-border-muted: #21262d;
    --color-neutral-muted: #6e768166;
    --color-accent-fg: #58a6ff;
    --color-accent-emphasis: #1f6feb;
    --color-attention-subtle: #bb800926;
    --color-danger-fg: #f85149;
  }
}

@media (prefers-color-scheme: light) {
  .wmde-markdown, .wmde-markdown-var {
    color-scheme: light;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-fg-default: #24292f;
    --color-fg-muted: #57606a;
    --color-fg-subtle: #6e7781;
    --color-canvas-default: #fff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: #d8dee4;
    --color-neutral-muted: #afb8c133;
    --color-accent-fg: #0969da;
    --color-accent-emphasis: #0969da;
    --color-attention-subtle: #fff8c5;
    --color-danger-fg: #cf222e;
  }
}

[data-color-mode*="dark"] .wmde-markdown, [data-color-mode*="dark"] .wmde-markdown-var, .wmde-markdown-var[data-color-mode*="dark"], .wmde-markdown[data-color-mode*="dark"], body[data-color-mode*="dark"] {
  color-scheme: dark;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-carriage-return-bg: #b62324;
  --color-prettylights-syntax-string-regexp: #7ee787;
  --color-prettylights-syntax-markup-list: #f2cc60;
  --color-prettylights-syntax-markup-heading: #1f6feb;
  --color-prettylights-syntax-markup-italic: #c9d1d9;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-markup-changed-text: #ffdfb6;
  --color-prettylights-syntax-markup-changed-bg: #5a1e02;
  --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
  --color-prettylights-syntax-markup-ignored-bg: #1158c7;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
  --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
  --color-fg-default: #c9d1d9;
  --color-fg-muted: #8b949e;
  --color-fg-subtle: #484f58;
  --color-canvas-default: #0d1117;
  --color-canvas-subtle: #161b22;
  --color-border-default: #30363d;
  --color-border-muted: #21262d;
  --color-neutral-muted: #6e768166;
  --color-accent-fg: #58a6ff;
  --color-accent-emphasis: #1f6feb;
  --color-attention-subtle: #bb800926;
  --color-danger-fg: #f85149;
}

[data-color-mode*="light"] .wmde-markdown, [data-color-mode*="light"] .wmde-markdown-var, .wmde-markdown-var[data-color-mode*="light"], .wmde-markdown[data-color-mode*="light"], body[data-color-mode*="light"] {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #fff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: #d8dee4;
  --color-neutral-muted: #afb8c133;
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}

.wmde-markdown {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-family: -apple-system, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
}

.wmde-markdown details, .wmde-markdown figcaption, .wmde-markdown figure {
  display: block;
}

.wmde-markdown summary {
  display: list-item;
}

.wmde-markdown [hidden] {
  display: none !important;
}

.wmde-markdown a {
  color: var(--color-accent-fg);
  background-color: #0000;
  text-decoration: none;
}

.wmde-markdown a:active, .wmde-markdown a:hover {
  outline-width: 0;
}

.wmde-markdown abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.wmde-markdown b, .wmde-markdown strong {
  font-weight: 600;
}

.wmde-markdown dfn {
  font-style: italic;
}

.wmde-markdown h1 {
  border-bottom: 1px solid var(--color-border-muted);
  margin: .67em 0;
  padding-bottom: .3em;
  font-size: 2em;
  font-weight: 600;
}

.wmde-markdown mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-text-primary);
}

.wmde-markdown small {
  font-size: 90%;
}

.wmde-markdown sub, .wmde-markdown sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.wmde-markdown sub {
  bottom: -.25em;
}

.wmde-markdown sup {
  top: -.5em;
}

.wmde-markdown img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
  border-style: none;
  display: inline-block;
}

.wmde-markdown code, .wmde-markdown kbd, .wmde-markdown pre, .wmde-markdown samp {
  font-family: monospace;
  font-size: 1em;
}

.wmde-markdown figure {
  margin: 1em 40px;
}

.wmde-markdown hr {
  box-sizing: content-box;
  border: 0;
  border-bottom: 1px solid var(--color-border-muted);
  height: .25em;
  background: none;
  background-color: var(--color-border-default);
  margin: 24px 0;
  padding: 0;
  overflow: hidden;
}

.wmde-markdown input {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;
}

.wmde-markdown [type="button"], .wmde-markdown [type="reset"], .wmde-markdown [type="submit"] {
  -webkit-appearance: button;
}

.wmde-markdown [type="button"]::-moz-focus-inner, .wmde-markdown [type="reset"]::-moz-focus-inner, .wmde-markdown [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.wmde-markdown [type="button"]:-moz-focusring, .wmde-markdown [type="reset"]:-moz-focusring, .wmde-markdown [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.wmde-markdown [type="checkbox"], .wmde-markdown [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.wmde-markdown [type="number"]::-webkit-inner-spin-button, .wmde-markdown [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.wmde-markdown [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.wmde-markdown [type="search"]::-webkit-search-cancel-button, .wmde-markdown [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.wmde-markdown ::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

.wmde-markdown ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.wmde-markdown a:hover {
  text-decoration: underline;
}

.wmde-markdown hr:before {
  content: "";
  display: table;
}

.wmde-markdown hr:after {
  clear: both;
  content: "";
  display: table;
}

.wmde-markdown table {
  border-spacing: 0;
  border-collapse: collapse;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  display: block;
}

.wmde-markdown td, .wmde-markdown th {
  padding: 0;
}

.wmde-markdown details summary {
  cursor: pointer;
}

.wmde-markdown details:not([open]) > :not(summary) {
  display: none !important;
}

.wmde-markdown kbd {
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
  border-radius: 6px;
  padding: 3px 5px;
  font: 11px / 10px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  display: inline-block;
}

.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.wmde-markdown td, .wmde-markdown th {
  padding: 0;
}

.wmde-markdown details summary {
  cursor: pointer;
}

.wmde-markdown details:not([open]) > :not(summary) {
  display: none !important;
}

.wmde-markdown kbd {
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
  border-radius: 6px;
  padding: 3px 5px;
  font: 11px / 10px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  display: inline-block;
}

.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.wmde-markdown h2 {
  border-bottom: 1px solid var(--color-border-muted);
  padding-bottom: .3em;
  font-size: 1.5em;
  font-weight: 600;
}

.wmde-markdown h3 {
  font-size: 1.25em;
  font-weight: 600;
}

.wmde-markdown h4 {
  font-size: 1em;
  font-weight: 600;
}

.wmde-markdown h5 {
  font-size: .875em;
  font-weight: 600;
}

.wmde-markdown h6 {
  color: var(--color-fg-muted);
  font-size: .85em;
  font-weight: 600;
}

.wmde-markdown p {
  margin-top: 0;
  margin-bottom: 10px;
}

.wmde-markdown blockquote {
  color: var(--color-fg-muted);
  border-left: .25em solid var(--color-border-default);
  margin: 0;
  padding: 0 1em;
}

.wmde-markdown ul, .wmde-markdown ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.wmde-markdown ol ol, .wmde-markdown ul ol {
  list-style-type: lower-roman;
}

.wmde-markdown ul ul ol, .wmde-markdown ul ol ol, .wmde-markdown ol ul ol, .wmde-markdown ol ol ol {
  list-style-type: lower-alpha;
}

.wmde-markdown dd {
  margin-left: 0;
}

.wmde-markdown tt, .wmde-markdown code {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}

.wmde-markdown pre {
  word-wrap: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}

.wmde-markdown .octicon {
  vertical-align: text-bottom;
  fill: currentColor;
  display: inline-block;
  overflow: visible !important;
}

.wmde-markdown ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}

.wmde-markdown input::-webkit-outer-spin-button, .wmde-markdown input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.wmde-markdown [data-catalyst] {
  display: block;
}

.wmde-markdown:before {
  content: "";
  display: table;
}

.wmde-markdown:after {
  clear: both;
  content: "";
  display: table;
}

.wmde-markdown > :first-child {
  margin-top: 0 !important;
}

.wmde-markdown > :last-child {
  margin-bottom: 0 !important;
}

.wmde-markdown a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.wmde-markdown .absent {
  color: var(--color-danger-fg);
}

.wmde-markdown a.anchor {
  float: left;
  margin-left: -20px;
  padding-right: 4px;
  line-height: 1;
}

.wmde-markdown .anchor:focus {
  outline: none;
}

.wmde-markdown p, .wmde-markdown blockquote, .wmde-markdown ul, .wmde-markdown ol, .wmde-markdown dl, .wmde-markdown table, .wmde-markdown pre, .wmde-markdown details {
  margin-top: 0;
  margin-bottom: 16px;
}

.wmde-markdown blockquote > :first-child {
  margin-top: 0;
}

.wmde-markdown blockquote > :last-child {
  margin-bottom: 0;
}

.wmde-markdown sup > a:before {
  content: "[";
}

.wmde-markdown sup > a:after {
  content: "]";
}

.wmde-markdown h1 .octicon-link, .wmde-markdown h2 .octicon-link, .wmde-markdown h3 .octicon-link, .wmde-markdown h4 .octicon-link, .wmde-markdown h5 .octicon-link, .wmde-markdown h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}

.wmde-markdown h1:hover .anchor, .wmde-markdown h2:hover .anchor, .wmde-markdown h3:hover .anchor, .wmde-markdown h4:hover .anchor, .wmde-markdown h5:hover .anchor, .wmde-markdown h6:hover .anchor {
  text-decoration: none;
}

.wmde-markdown h1:hover .anchor .octicon-link, .wmde-markdown h2:hover .anchor .octicon-link, .wmde-markdown h3:hover .anchor .octicon-link, .wmde-markdown h4:hover .anchor .octicon-link, .wmde-markdown h5:hover .anchor .octicon-link, .wmde-markdown h6:hover .anchor .octicon-link {
  visibility: visible;
}

.wmde-markdown h1 tt, .wmde-markdown h1 code, .wmde-markdown h2 tt, .wmde-markdown h2 code, .wmde-markdown h3 tt, .wmde-markdown h3 code, .wmde-markdown h4 tt, .wmde-markdown h4 code, .wmde-markdown h5 tt, .wmde-markdown h5 code, .wmde-markdown h6 tt, .wmde-markdown h6 code {
  font-size: inherit;
  padding: 0 .2em;
}

.wmde-markdown ul.no-list, .wmde-markdown ol.no-list {
  padding: 0;
  list-style-type: none;
}

.wmde-markdown ol[type="1"] {
  list-style-type: decimal;
}

.wmde-markdown ol[type="a"] {
  list-style-type: lower-alpha;
}

.wmde-markdown ol[type="i"] {
  list-style-type: lower-roman;
}

.wmde-markdown div > ol:not([type]) {
  list-style-type: decimal;
}

.wmde-markdown ul ul, .wmde-markdown ul ol, .wmde-markdown ol ol, .wmde-markdown ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.wmde-markdown li > p {
  margin-top: 16px;
}

.wmde-markdown li + li {
  margin-top: .25em;
}

.wmde-markdown dl {
  padding: 0;
}

.wmde-markdown dl dt {
  margin-top: 16px;
  padding: 0;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.wmde-markdown dl dd {
  margin-bottom: 16px;
  padding: 0 16px;
}

.wmde-markdown table th {
  font-weight: 600;
}

.wmde-markdown table th, .wmde-markdown table td {
  border: 1px solid var(--color-border-default);
  padding: 6px 13px;
}

.wmde-markdown table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}

.wmde-markdown table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}

.wmde-markdown table img {
  background-color: #0000;
}

.wmde-markdown img[align="right"] {
  padding-left: 20px;
}

.wmde-markdown img[align="left"] {
  padding-right: 20px;
}

.wmde-markdown .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: #0000;
}

.wmde-markdown span.frame {
  display: block;
  overflow: hidden;
}

.wmde-markdown span.frame > span {
  float: left;
  width: auto;
  border: 1px solid var(--color-border-default);
  margin: 13px 0 0;
  padding: 7px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.frame span img {
  float: left;
  display: block;
}

.wmde-markdown span.frame span span {
  clear: both;
  color: var(--color-fg-default);
  padding: 5px 0 0;
  display: block;
}

.wmde-markdown span.align-center {
  clear: both;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-center > span {
  text-align: center;
  margin: 13px auto 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-center span img {
  text-align: center;
  margin: 0 auto;
}

.wmde-markdown span.align-right {
  clear: both;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-right > span {
  text-align: right;
  margin: 13px 0 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-right span img {
  text-align: right;
  margin: 0;
}

.wmde-markdown span.float-left {
  float: left;
  margin-right: 13px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.float-left span {
  margin: 13px 0 0;
}

.wmde-markdown span.float-right {
  float: right;
  margin-left: 13px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.float-right > span {
  text-align: right;
  margin: 13px auto 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown code, .wmde-markdown tt {
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
  margin: 0;
  padding: .2em .4em;
  font-size: 85%;
}

.wmde-markdown code br, .wmde-markdown tt br {
  display: none;
}

.wmde-markdown del code {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.wmde-markdown pre code {
  font-size: 100%;
}

.wmde-markdown pre > code {
  word-break: normal;
  white-space: pre;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.wmde-markdown pre {
  background-color: var(--color-canvas-subtle);
  border-radius: 6px;
  font-size: 85%;
  line-height: 1.45;
}

.wmde-markdown pre code, .wmde-markdown pre tt {
  max-width: auto;
  line-height: inherit;
  word-wrap: normal;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline;
  overflow: visible;
}

.wmde-markdown pre > code {
  padding: 16px;
  display: block;
  overflow: auto;
}

.wmde-markdown .csv-data td, .wmde-markdown .csv-data th {
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  font-size: 12px;
  line-height: 1;
  overflow: hidden;
}

.wmde-markdown .csv-data .blob-num {
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
  padding: 10px 8px 9px;
}

.wmde-markdown .csv-data tr {
  border-top: 0;
}

.wmde-markdown .csv-data th {
  background: var(--color-canvas-subtle);
  border-top: 0;
  font-weight: 600;
}

.wmde-markdown .footnotes {
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
  font-size: 12px;
}

.wmde-markdown .footnotes ol {
  padding-left: 16px;
}

.wmde-markdown .footnotes li {
  position: relative;
}

.wmde-markdown .footnotes li:target:before {
  pointer-events: none;
  content: "";
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
  position: absolute;
  inset: -8px -8px -8px -24px;
}

.wmde-markdown .footnotes li:target {
  color: var(--color-fg-default);
}

.wmde-markdown .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}

.wmde-markdown .task-list-item {
  list-style-type: none;
}

.wmde-markdown .task-list-item label {
  font-weight: 400;
}

.wmde-markdown .task-list-item.enabled label {
  cursor: pointer;
}

.wmde-markdown .task-list-item + .wmde-markdown .task-list-item {
  margin-top: 3px;
}

.wmde-markdown .task-list-item .handle {
  display: none;
}

.wmde-markdown .task-list-item-checkbox, .wmde-markdown .contains-task-list input[type="checkbox"] {
  vertical-align: middle;
  margin: 0 .2em .25em -1.6em;
}

.wmde-markdown .contains-task-list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) .task-list-item-checkbox, .wmde-markdown .contains-task-list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) input[type="checkbox"] {
  margin: 0 -1.6em .25em .2em;
}

.wmde-markdown ::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(50%);
  filter: invert(50%);
}

.wmde-markdown pre[class*="language-"] {
  position: relative;
}

.wmde-markdown pre .copied {
  visibility: hidden;
  cursor: pointer;
  color: var(--color-fg-defaul);
  background: var(--color-border-default);
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
}

.wmde-markdown pre .copied .octicon-copy {
  display: block;
}

.wmde-markdown pre .copied .octicon-check {
  display: none;
}

.wmde-markdown pre:hover .copied {
  visibility: visible;
}

.wmde-markdown pre:hover .copied:hover {
  background: var(--color-prettylights-syntax-entity-tag);
  color: var(--color-canvas-default);
}

.wmde-markdown pre:hover .copied:active, .wmde-markdown pre .copied.active {
  color: var(--color-canvas-default);
  background: #2e9b33;
}

.wmde-markdown pre .active .octicon-copy {
  display: none;
}

.wmde-markdown pre .active .octicon-check {
  display: block;
}

.highlight-line {
  background-color: var(--color-neutral-muted);
}

.code-line.line-number:before {
  width: 1rem;
  text-align: right;
  color: var(--color-fg-subtle);
  content: attr(line);
  white-space: nowrap;
  margin-right: 16px;
  display: inline-block;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: var(--color-prettylights-syntax-comment);
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.selector, .token.constant, .token.symbol, .token.deleted {
  color: var(--color-prettylights-syntax-entity-tag);
}

.token.maybe-class-name {
  color: var(--color-prettylights-syntax-variable);
}

.token.property-access, .token.operator, .token.boolean, .token.number, .token.selector .token.class, .token.attr-name, .token.string, .token.char, .token.builtin {
  color: var(--color-prettylights-syntax-constant);
}

.token.deleted {
  color: var(--color-prettylights-syntax-markup-deleted-text);
}

.code-line .token.deleted {
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}

.token.inserted {
  color: var(--color-prettylights-syntax-markup-inserted-text);
}

.code-line .token.inserted {
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}

.token.variable {
  color: var(--color-prettylights-syntax-constant);
}

.token.entity, .token.url, .language-css .token.string, .style .token.string, .token.color, .token.atrule, .token.attr-value, .token.function, .token.class-name {
  color: var(--color-prettylights-syntax-string);
}

.token.rule, .token.regex, .token.important, .token.keyword {
  color: var(--color-prettylights-syntax-keyword);
}

.token.coord {
  color: var(--color-prettylights-syntax-meta-diff-range);
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.w-md-editor-area {
  border-radius: 5px;
  overflow: auto;
}

.w-md-editor-text {
  min-height: 100%;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  box-sizing: border-box;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  margin: 0;
  padding: 10px;
  position: relative;
  font-size: 14px !important;
  line-height: 18px !important;
}

.w-md-editor-text-pre, .w-md-editor-text-input, .w-md-editor-text > .w-md-editor-text-pre {
  box-sizing: inherit;
  display: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  -webkit-font-variant-ligatures: inherit;
  font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  tab-size: inherit;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: inherit;
  overflow-wrap: inherit;
  word-break: inherit;
  word-break: normal;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.w-md-editor-text-pre > code, .w-md-editor-text-input > code, .w-md-editor-text > .w-md-editor-text-pre > code {
  font-family: inherit;
}

.w-md-editor-text-pre {
  pointer-events: none;
  position: relative;
  background-color: #0000 !important;
  margin: 0 !important;
}

.w-md-editor-text-pre > code {
  padding: 0 !important;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.w-md-editor-text-input {
  height: 100%;
  width: 100%;
  resize: none;
  color: inherit;
  padding: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-fill-color: transparent;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.w-md-editor-text-input:empty {
  -webkit-text-fill-color: inherit !important;
}

.w-md-editor-text-pre, .w-md-editor-text-input {
  word-wrap: pre;
  word-break: break-word;
  white-space: pre-wrap;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-md-editor-text-input {
    color: #0000 !important;
  }

  .w-md-editor-text-input::selection {
    color: #0000 !important;
    background-color: #accef7 !important;
  }
}

.w-md-editor-text-pre .punctuation {
  color: var(--color-prettylights-syntax-comment) !important;
}

.w-md-editor-text-pre .token.url, .w-md-editor-text-pre .token.content {
  color: var(--color-prettylights-syntax-constant) !important;
}

.w-md-editor-text-pre .token.title.important {
  color: var(--color-prettylights-syntax-markup-bold);
}

.w-md-editor-text-pre .token.code-block .function {
  color: var(--color-prettylights-syntax-entity);
}

.w-md-editor-text-pre .token.bold {
  font-weight: unset !important;
}

.w-md-editor-text-pre .token.title {
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
}

.w-md-editor-text-pre .token.code.keyword {
  color: var(--color-prettylights-syntax-constant) !important;
}

.w-md-editor-text-pre .token.strike, .w-md-editor-text-pre .token.strike .content {
  color: var(--color-prettylights-syntax-markup-deleted-text) !important;
}

.w-md-editor-toolbar-child {
  box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default), 0 1px 1px var(--color-border-default);
  background-color: var(--color-canvas-default);
  z-index: 1;
  border-radius: 3px;
  display: none;
  position: absolute;
}

.w-md-editor-toolbar-child.active {
  display: block;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar {
  border-bottom: 0;
  border-radius: 3px;
  padding: 3px;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
  display: block;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
  width: -webkit-fill-available;
  height: initial;
  box-sizing: border-box;
  margin: 0;
  padding: 3px 4px 2px;
}

.w-md-editor-toolbar {
  border-bottom: 1px solid var(--color-border-default);
  background-color: var(--color-canvas-default);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px 3px 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  display: flex;
}

.w-md-editor-toolbar.bottom {
  border-bottom: 0;
  border-top: 1px solid var(--color-border-default);
  border-radius: 0 0 3px 3px;
}

.w-md-editor-toolbar ul, .w-md-editor-toolbar li {
  line-height: initial;
  margin: 0;
  padding: 0;
  list-style: none;
}

.w-md-editor-toolbar li {
  font-size: 14px;
  display: inline-block;
}

.w-md-editor-toolbar li + li {
  margin: 0;
}

.w-md-editor-toolbar li > button {
  height: 20px;
  text-transform: none;
  cursor: pointer;
  white-space: nowrap;
  color: var(--color-fg-default);
  background: none;
  border: none;
  border-radius: 2px;
  outline: none;
  margin: 0 1px;
  padding: 4px;
  font-weight: normal;
  line-height: 14px;
  transition: all .3s;
  overflow: visible;
}

.w-md-editor-toolbar li > button:hover, .w-md-editor-toolbar li > button:focus {
  background-color: var(--color-neutral-muted);
  color: var(--color-accent-fg);
}

.w-md-editor-toolbar li > button:active {
  background-color: var(--color-neutral-muted);
  color: var(--color-danger-fg);
}

.w-md-editor-toolbar li > button:disabled {
  color: var(--color-border-default);
  cursor: not-allowed;
}

.w-md-editor-toolbar li > button:disabled:hover {
  color: var(--color-border-default);
  background-color: #0000;
}

.w-md-editor-toolbar li.active > button {
  color: var(--color-accent-fg);
  background-color: var(--color-neutral-muted);
}

.w-md-editor-toolbar-divider {
  height: 14px;
  width: 1px;
  vertical-align: middle;
  background-color: var(--color-border-default);
  margin: -3px 3px 0 !important;
}

.w-md-editor-bar {
  cursor: s-resize;
  width: 14px;
  z-index: 3;
  height: 10px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 0 3px;
  margin-top: -11px;
  margin-right: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.w-md-editor-bar svg {
  margin: 0 auto;
  display: block;
}

.w-md-editor {
  text-align: left;
  color: var(--color-fg-default);
  box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default), 0 1px 1px var(--color-border-default);
  background-color: var(--color-canvas-default);
  border-radius: 3px;
  flex-direction: column;
  padding-bottom: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
}

.w-md-editor.w-md-editor-rtl {
  direction: rtl !important;
  text-align: right !important;
}

.w-md-editor.w-md-editor-rtl .w-md-editor-preview {
  box-shadow: inset -1px 0 0 0 var(--color-border-default);
  left: 0;
  right: unset !important;
  text-align: right !important;
}

.w-md-editor.w-md-editor-rtl .w-md-editor-text {
  text-align: right !important;
}

.w-md-editor-toolbar {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.w-md-editor-content {
  height: 100%;
  border-radius: 0 0 3px;
  position: relative;
  overflow: auto;
}

.w-md-editor .copied {
  display: none !important;
}

.w-md-editor-input {
  width: 50%;
  height: 100%;
}

.w-md-editor-text-pre > code {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 var(--color-border-default);
  border-radius: 0 0 5px;
  flex-direction: column;
  padding: 10px 20px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.w-md-editor-preview .anchor {
  display: none;
}

.w-md-editor-preview .contains-task-list {
  list-style: none;
}

.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  background-color: var(--color-canvas-default);
  overflow: hidden;
}

.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0;
}

.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}

.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}

.w-md-editor-fullscreen {
  z-index: 99999;
  position: fixed;
  inset: 0;
  overflow: hidden;
  height: 100% !important;
}

.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}

.emoji-mart, .emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  color: #222427;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
  font-size: 16px;
  display: inline-block;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.emoji-mart-anchors {
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
  display: flex;
}

.emoji-mart-anchor {
  color: #858585;
  text-align: center;
  box-shadow: none;
  background: none;
  border: none;
  flex: auto;
  margin: 0;
  padding: 12px 4px;
  transition: color .1s ease-out;
  display: block;
  position: relative;
  overflow: hidden;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover, .emoji-mart-anchor:focus, .emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  width: 100%;
  height: 3px;
  background-color: #464646;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.emoji-mart-anchors i {
  width: 100%;
  max-width: 22px;
  display: inline-block;
}

.emoji-mart-anchors svg, .emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  height: 270px;
  will-change: transform;
  padding: 0 6px 6px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  outline: 0;
  padding: 5px 25px 6px 10px;
  font-size: 16px;
  display: block;
}

.emoji-mart-search input, .emoji-mart-search input::-webkit-search-decoration, .emoji-mart-search input::-webkit-search-cancel-button, .emoji-mart-search input::-webkit-search-results-button, .emoji-mart-search input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  z-index: 2;
  background: none;
  border: none;
  padding: 2px 5px 1px;
  position: absolute;
  top: 7px;
  right: 11px;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  text-align: center;
  cursor: default;
  position: relative;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.emoji-mart-category-label {
  z-index: 2;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  width: 100%;
  background-color: #fffffff2;
  padding: 5px 6px;
  font-weight: 500;
  display: block;
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.emoji-mart-emoji {
  box-shadow: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.emoji-mart-emoji-native {
  font-family: Segoe UI Emoji, Segoe UI Symbol, Segoe UI, Apple Color Emoji, Twemoji Mozilla, Noto Color Emoji, Android Emoji;
}

.emoji-mart-no-results {
  text-align: center;
  color: #858585;
  padding-top: 70px;
  font-size: 14px;
}

.emoji-mart-no-results-img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  height: 70px;
  position: relative;
}

.emoji-mart-preview-emoji, .emoji-mart-preview-data, .emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  word-break: break-all;
  left: 68px;
  right: 12px;
}

.emoji-mart-preview-skins {
  text-align: right;
  right: 30px;
}

.emoji-mart-preview-skins.custom {
  text-align: right;
  right: 10px;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  color: #888;
  font-size: 12px;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname, .emoji-mart-preview-shortname + .emoji-mart-preview-emoticon, .emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em;
}

.emoji-mart-preview-emoticon {
  color: #bbb;
  font-size: 11px;
}

.emoji-mart-title span {
  vertical-align: middle;
  display: inline-block;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999a9c;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 2px 0;
  font-size: 0;
}

.emoji-mart-skin-swatches.custom {
  background-color: #fff;
  border: none;
  font-size: 0;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: .75;
}

.emoji-mart-skin-swatch {
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: .125s;
  transition-timing-function: ease-out;
  display: inline-block;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 30ms;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 60ms;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 90ms;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: .12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: .15s;
}

.emoji-mart-skin-swatch.selected {
  width: 16px;
  padding: 0 2px;
  position: relative;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  width: 4px;
  height: 4px;
  pointer-events: none;
  opacity: 0;
  background-color: #fff;
  border-radius: 100%;
  margin: -2px 0 0 -2px;
  transition: opacity .2s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

.emoji-mart-skin-swatch.custom {
  width: 0;
  height: 38px;
  vertical-align: middle;
  cursor: default;
  transition-property: width, height;
  transition-duration: .125s;
  transition-timing-function: ease-out;
  display: inline-block;
  overflow: hidden;
}

.emoji-mart-skin-swatch.custom.selected {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
  position: relative;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: .75;
}

.emoji-mart-skin-text.opened {
  vertical-align: middle;
  text-align: left;
  color: #888;
  width: 95px;
  height: 40px;
  background-color: #fff;
  border-radius: 10%;
  padding: 5px 2px;
  font-size: 11px;
  display: inline-block;
}

.emoji-mart-skin {
  width: 100%;
  max-width: 12px;
  border-radius: 100%;
  padding-top: 100%;
  display: inline-block;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

.emoji-mart-sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.emoji-mart-dark {
  color: #fff;
  background-color: #222;
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  color: #fff;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  background-color: #222;
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-anchor:hover, .emoji-mart-dark .emoji-mart-anchor:focus, .emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

* {
  box-sizing: border-box;
}

/*# sourceMappingURL=index.98e89c0d.css.map */
