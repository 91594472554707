.w-md-editor-toolbar {
  border-bottom: 1px solid var(--color-border-default);
  background-color: var(--color-canvas-default);
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px 3px 0 0;
  -webkit-user-select: none;
          user-select: none;
  flex-wrap: wrap;
}
.w-md-editor-toolbar.bottom {
  border-bottom: 0px;
  border-top: 1px solid var(--color-border-default);
  border-radius: 0 0 3px 3px;
}
.w-md-editor-toolbar ul,
.w-md-editor-toolbar li {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: initial;
}
.w-md-editor-toolbar li {
  display: inline-block;
  font-size: 14px;
}
.w-md-editor-toolbar li + li {
  margin: 0;
}
.w-md-editor-toolbar li > button {
  border: none;
  height: 20px;
  line-height: 14px;
  background: none;
  padding: 4px;
  margin: 0 1px;
  border-radius: 2px;
  text-transform: none;
  font-weight: normal;
  overflow: visible;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  color: var(--color-fg-default);
}
.w-md-editor-toolbar li > button:hover,
.w-md-editor-toolbar li > button:focus {
  background-color: var(--color-neutral-muted);
  color: var(--color-accent-fg);
}
.w-md-editor-toolbar li > button:active {
  background-color: var(--color-neutral-muted);
  color: var(--color-danger-fg);
}
.w-md-editor-toolbar li > button:disabled {
  color: var(--color-border-default);
  cursor: not-allowed;
}
.w-md-editor-toolbar li > button:disabled:hover {
  background-color: transparent;
  color: var(--color-border-default);
}
.w-md-editor-toolbar li.active > button {
  color: var(--color-accent-fg);
  background-color: var(--color-neutral-muted);
}
.w-md-editor-toolbar-divider {
  height: 14px;
  width: 1px;
  margin: -3px 3px 0 3px !important;
  vertical-align: middle;
  background-color: var(--color-border-default);
}
