.w-md-editor-area {
  overflow: auto;
  border-radius: 5px;
}
.w-md-editor-text {
  min-height: 100%;
  position: relative;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  font-size: 14px !important;
  line-height: 18px !important;
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
}
.w-md-editor-text-pre,
.w-md-editor-text-input,
.w-md-editor-text > .w-md-editor-text-pre {
  margin: 0;
  border: 0;
  background: none;
  box-sizing: inherit;
  display: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  -webkit-font-variant-ligatures: inherit;
          font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  tab-size: inherit;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: inherit;
  overflow-wrap: inherit;
  word-break: inherit;
  word-break: normal;
  padding: 0;
}
.w-md-editor-text-pre > code,
.w-md-editor-text-input > code,
.w-md-editor-text > .w-md-editor-text-pre > code {
  font-family: inherit;
}
.w-md-editor-text-pre {
  position: relative;
  margin: 0px !important;
  pointer-events: none;
  background-color: transparent !important;
}
.w-md-editor-text-pre > code {
  padding: 0 !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
.w-md-editor-text-input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  resize: none;
  color: inherit;
  overflow: hidden;
  outline: 0;
  padding: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-fill-color: transparent;
}
.w-md-editor-text-input:empty {
  -webkit-text-fill-color: inherit !important;
}
.w-md-editor-text-pre,
.w-md-editor-text-input {
  word-wrap: pre;
  word-break: break-word;
  white-space: pre-wrap;
}
/**
 * Hack to apply on some CSS on IE10 and IE11
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /**
    * IE doesn't support '-webkit-text-fill-color'
    * So we use 'color: transparent' to make the text transparent on IE
    * Unlike other browsers, it doesn't affect caret color in IE
    */
  .w-md-editor-text-input {
    color: transparent !important;
  }
  .w-md-editor-text-input::selection {
    background-color: #accef7 !important;
    color: transparent !important;
  }
}
.w-md-editor-text-pre .punctuation {
  color: var(--color-prettylights-syntax-comment) !important;
}
.w-md-editor-text-pre .token.url,
.w-md-editor-text-pre .token.content {
  color: var(--color-prettylights-syntax-constant) !important;
}
.w-md-editor-text-pre .token.title.important {
  color: var(--color-prettylights-syntax-markup-bold);
}
.w-md-editor-text-pre .token.code-block .function {
  color: var(--color-prettylights-syntax-entity);
}
.w-md-editor-text-pre .token.bold {
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.title {
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
}
.w-md-editor-text-pre .token.code.keyword {
  color: var(--color-prettylights-syntax-constant) !important;
}
.w-md-editor-text-pre .token.strike,
.w-md-editor-text-pre .token.strike .content {
  color: var(--color-prettylights-syntax-markup-deleted-text) !important;
}
